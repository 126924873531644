import React, { FC } from "react";
import { classnames } from "../../lib/classnames";
import "./style.scss";

interface ColumnProps {
  label: string;
  render: (obj: any) => React.ReactNode;
  labelVisible?: boolean;
  width?: number;
  colSize?: number;
}

interface FlexTableProps {
  data?: any[];
  columns: ColumnProps[];
  className?: string;
  id?: string;
}

const FlexTable: FC<FlexTableProps> = (props: FlexTableProps) => {
  const { id, className, data, columns } = props;

  const getVirtualWidth = (colSize?: number): number => {
    if (!colSize || colSize < 0) return 1;
    return Math.floor(colSize);
  };

  // Hacky way to make rows of flex divs looks like a table
  const generateWidthClass = (
    virtualColCount: number,
    colSize?: number
  ): string => {
    let size = getVirtualWidth(colSize);
    let weight = (Math.round((virtualColCount / size) * 10) / 10)
      .toString()
      .replace(".", "-");
    return `flex-col-${weight}`;
  };

  // count total number of virtual colums (includes colSize of indiviual cols)
  let virtualColumnCount: number = 0;
  Object.values(props.columns).forEach((colProp) => {
    virtualColumnCount += getVirtualWidth(colProp.colSize);
  });

  return (
    <div id={id} className={classnames(["flex-table", className])}>
      <div className="flex-table-header-wrapper">
        <div className="flex-table-header flex-row">
          {columns.map((colProps) => (
            <div
              key={`header(${colProps.label})`}
              className={classnames([
                "flex-cell",
                generateWidthClass(virtualColumnCount, colProps.colSize),
              ])}
              style={{ width: colProps.width }}
            >
              {colProps.label}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-table-data-wrapper">
        {data?.map((datum, index) => (
          <div key={index} className="flex-table-data flex-row">
            {columns.map((colProps) => (
              <div
                key={`data(${colProps.label})(${index})`}
                className={classnames([
                  "flex-cell",
                  generateWidthClass(virtualColumnCount, colProps.colSize),
                ])}
                style={{ width: colProps.width }}
              >
                <div
                  className={classnames({
                    "mobile-label": true,
                    "mobile-label-hidden": colProps.labelVisible === false,
                  })}
                >
                  {colProps.label}
                </div>
                {colProps.render(datum)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlexTable;
