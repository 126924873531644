import React, { FC, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image } from '@vacasa/react-components-lib';
import { MonitoringDevice } from '../../../../services/SmartHomeDevices/types';
import VerticalFill from '../../../../components/VerticalFill';
import FlexTable from '../../../../components/FlexTable';
import { formatMacAddressForDisplay } from '../../../../lib/format.macaddress';
import { formatDateTime } from '../../../../lib/format.timestamp';

import './style.scss';
import { formatReading } from '../../../../lib/format.reading';
import { formatThreshold } from '../../../../lib/format.threshold';

interface DevicesDataTableProps {
  devices?: MonitoringDevice[];
  page?: string;
  isLoading?: boolean;
  error?: string;
  fetchData: () => Promise<void>;
  onExport: () => void;
}

const DevicesDataTable: FC<DevicesDataTableProps> = ({
  devices,
  page,
  isLoading,
  error,
  fetchData,
  onExport,
}) => {
  const tableId = 'monitioring-device-table';
  const footerRef = useRef<HTMLDivElement>(null);

  const handleExport = () => {
    onExport?.();
  };

  return (
    <div className="devices-data-table-component">
      <InfiniteScroll
        dataLength={devices?.length ?? 0}
        next={fetchData}
        hasMore={!devices || !!page}
        loader=""
        scrollableTarget={tableId}
        scrollThreshold={1}
      >
        <VerticalFill marginBottom={footerRef.current?.clientHeight}>
          <FlexTable
            id={tableId}
            data={devices}
            columns={[
              {
                label: 'Device Type',
                render: (device: MonitoringDevice) => device.DeviceType,
                width: 60,
              },
              {
                label: 'MAC Address',
                render: (device: MonitoringDevice) => formatMacAddressForDisplay(device.MacAddress),
              },
              {
                label: 'Unit ID',
                render: (device: MonitoringDevice) => device.DeviceGroup,
                width: 60,
              },
              {
                label: 'Unit Code',
                render: (device: MonitoringDevice) => device.UnitCode || 'Unknown',
                width: 90,
              },
              {
                label: 'Finance Code',
                render: (device) => device.FinanceCode || 'Unknown',
                width: 90,
              },
              {
                label: 'Latest Reading Date',
                render: (device: MonitoringDevice) =>
                  formatDateTime(device.LatestReading?.ReadingDate),
              },
              {
                label: 'Latest Reading',
                render: (device: MonitoringDevice) =>
                  formatReading(device.DeviceType, device.LatestReading),
              },
              {
                label: 'Reading Threshold',
                render: (device: MonitoringDevice) => formatThreshold(device),
              },
              {
                label: 'Registered At',
                render: (device: MonitoringDevice) => formatDateTime(device.RegistrationDate),
              },
              {
                label: 'Registered By',
                render: (device: MonitoringDevice) =>
                  device.RegisteredByEmailAddress ?? device.UpdatedByEmailAddress,
              },
              {
                label: 'Last Changed By',
                render: (device: MonitoringDevice) => device.UpdatedByEmailAddress,
              },
            ]}
          />
        </VerticalFill>
        <div className="device-table-footer" ref={footerRef}>
          <div className="center">
            {isLoading && (
              <div className="spinner">
                <Image.Spinner width={36} height={36} />
              </div>
            )}
            {!isLoading && !error && !page && (
              <div>displaying all {devices?.length ?? 0} devices</div>
            )}
            {error && (
              <div className="dashboard-error">
                <span>{error} </span>
                <button className="white mini" onClick={fetchData}>
                  Retry
                </button>
              </div>
            )}
          </div>
          <div className="export">
            <button onClick={handleExport} disabled={isLoading || !devices || devices.length === 0}>
              Export
            </button>
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default DevicesDataTable;
