import { AxiosError, AxiosResponse } from "axios";

export class HttpResponseError extends Error {
  status: number;
  request: string = "";
  response: any;
  constructor(res: AxiosResponse) {
    super(`Error ${res.status} ${res.statusText}`);
    this.name = this.constructor.name;
    this.status = res.status;
    this.response = res.data;
    this.request = `${res.config.method?.toUpperCase()} ${res.config.baseURL}${
      res.config.url
    }`;
  }
}

export const DEFAULT_TIMEOUT = 15000;
export class HttpTimeoutError extends Error {
  constructor(err: AxiosError) {
    super(
      `Request timed out after ${
        (err.config?.timeout ?? DEFAULT_TIMEOUT) / 1000
      } seconds`
    );
  }
}
